import React from "react";
import PrintLayout from "../components/print-layout";
import logo from "../images/fluid-logo.png";

const PrintCouponPage = ({location}) => {
	const promotion = (location && location.state)? location.state.promotion : {};
	const { discount, description } = promotion;

	return (
		<PrintLayout>
			<div className="fp-promotion-item">
				<img className="fp-logo" src={logo} alt="fluid plumbing and heating logo" />
				<span className="fp-promotion-title">{discount}</span>
				<span className="fp-promotion-description">{description}</span>
			</div>
		</PrintLayout>
	);
};

export default PrintCouponPage
