import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import '../styles/index.css';
import {Link} from "gatsby";


const printCoupon = () => {
	if(window){
		window.print();
	}
};

const PrintLayout = ({ children }) => (
	<>
		<Helmet>
			<title>Print Coupon</title>
			<meta charSet="UTF-8" />
			<meta name="description" content="" />
			<meta name="keywords" content="" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
		</Helmet>

		<section className="print-header">
			<Link to="/">Back</Link>
			<br/>
			<button className="fp-button"
			        onClick={printCoupon}>
				Print
			</button>
		</section>
		<section className="print-main">
			{children}
		</section>
	</>
);

PrintLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PrintLayout
